import React from "react"
import Layout from "../Components/Layout"
import Box from "../Components/Box"

import Place1 from "../assets/images/places/place-1.png"
import Place2 from "../assets/images/places/place-2.png"
import Place3 from "../assets/images/places/place-3.png"
import Place4 from "../assets/images/places/place-4.png"
import Place5 from "../assets/images/places/place-5.png"
import Place6 from "../assets/images/places/place-6.png"
import { Link } from "gatsby"
import LrtLogoDark from "../assets/svg/lrtl.svg"
import MmoLogoDark from "../assets/svg/Group 36.svg"
import TopIlliustration from "../assets/images/Padavejas.png"
import LrtLogo from "../assets/svg/lrtl.svg"
import MmoLogo from "../assets/svg/Group 36.svg"
import { RaisedButton } from "../Components/Buttons"

const PRIZES = [
  {
    place: "1 vieta",
    prize: (<div>Išskirtinė ekskursija MO saugykloje tau ir 10 tavo svečių + 2 MOdernisto kortelės</div>),
    text: (
      <p>
        Itin reta galimybė apsilankyti MO muziejaus saugykloje. Tik tau ir 10 drauge su tavimi atėjusių draugų.
        Ekskursiją saugykloje ves ir apie įdomiausius kūrinius bei naujausius kolekcijos įsigijimus papasakos pati MO
        fondų saugotoja Ieva! O ji tikrai turi kuo pasidalinti.
        <br/>
        <br/>
        Be to, į MO saugyklą ne visi taip lengvai patenka – nors per stiklą žvilgtelėti gali visi MO lankytojai, iš
        arčiau pamatyti saugomus meno kūrinius galima tik ypatingais atvejais!
        <br/>
        <br/>
        Prizinės vietos nugalėtojas gaus ir 2 MOdernisto korteles. Tai metinė MO narystė ir galimybė neribotai lankytis
        muziejuje ištisus metus. Taip pat MOdernistų laukia specialūs renginiai, nuolaidos ir kiti išskirtiniai
        pasiūlymai. Daugiau sužinoki <Link to="https://mo.lt/modernistas/">čia</Link>
      </p>
    ),
    image: Place1
  },
  {
    place: "2 vieta",
    prize: (
      <div>Dėmesingo įsisąmoninimo (<i>mindfulness</i>) užsiėmimas MO muziejuje tau ir 10 tavo svečių + 2 MOdernisto
        kortelės</div>),
    text: (
      <p>
        Šiemet paruošėme dar vieną naują patirtį MO – visame pasaulyje vis labiau
        populiarėjančia <i>mindfulness</i> praktika grįstus užsiėmimus muziejuje. Tad tu ir 10 tavo draugų turėsite
        galimybę juos patirti vieni pirmųjų!
        <br/>
        <br/>
        Tai galimybė pažinti save, suprasti, kaip reaguoji į meną, atsiverti aplinkos patyrimui. Dėmesingo įsisąmoninimo
        praktikos padeda mažinti įtampą, palaikyti tvirtesnius tarpasmeninius santykius, o tai pokarantininėje realybėje
        mums šitaip svarbu!
        <br/>
        <br/>
        Prizinės vietos nugalėtojas gaus ir 2 MOdernisto korteles. Tai metinė MO narystė ir galimybė neribotai lankytis
        muziejuje ištisus metus. Taip pat MOdernistų laukia specialūs renginiai, nuolaidos ir kiti išskirtiniai
        pasiūlymai. Daugiau sužinoki <Link to="https://mo.lt/modernistas/">čia</Link>
      </p>
    ),
    image: Place2
  },
  {
    place: "3 vieta",
    prize: (<div>Privatus 33 minučių patyrimo seansas tau ir 10 tavo svečių + 2 MOdernisto kortelės</div>),
    image: Place3,
    text: (
      <p>
        Novatoriškas ir netradicinis MO muziejaus siūlomas formatas – 33 minutes trunkantis visiškai saugus,
        paslaptingas ir dabartines realijas atitinkantis patyrimas.
        <br/>
        <br/>
        Tavęs ir 10 tavo draugų laukia įvairiapusė MO kolekcijos kūrinio interpretacija ir netikėtumas – ją lydinti
        garsų ir netgi skonio patirtis. Intrigos daug, beliks tik sukviesti draugus ir atvykti!
        <br/>
        <br/>
        Prizinės vietos nugalėtojas gaus ir 2 MOdernisto korteles. Tai metinė MO narystė ir galimybė neribotai lankytis
        muziejuje ištisus metus. Taip pat MOdernistų laukia specialūs renginiai, nuolaidos ir kiti išskirtiniai
        pasiūlymai. Daugiau sužinoki <Link to="https://mo.lt/modernistas/">čia</Link>
      </p>
    )
  }
]

const PRIZESM = [
  {
    place: "1 vieta",
    prize: (<div>Naujausia MO edukacija Vilniaus mieste apie grafičio meną tau ir tavo klasės draugams</div>),
    text: (
      <p>
        „Grafičio piešiniai: meninė raiška ar miestą teršianti keverzonė?“ – nauja, netikėtų atradimų kupina MO
        edukacija. Jos metu kartu su klasės draugais ir MO edukatoriumi tyrinėsi skirtingas Vilniaus erdves: susipažinsi
        su grafičio menu sostinės gatvėse ir pažvelgsi į jį iš skirtingų pusių.
        <br/>
        <br/>
        Pažindamas grafičius daugiau sužinosi ir apie modernųjį meną – naujai atrasi MO kolekcijos kūrinius, kuriuose
        irgi yra grafičio bruožų!
        <br/>
        <br/>
        Edukacijos įspūdžiais kartu su klasės draugais galėsi aptarti sugrįžęs į muziejų – mielai kviesime aplankyti
        visas naujausias MO parodas. Gal jose taip pat pavyks atrasti grafitį menančių kūrinių?
      </p>
    ),
    image: Place4
  },
  {
    place: "2 vieta",
    prize: (<div>Riboto leidimo marškinėliai su <a href="https://mo.lt/modiseja-2021-marskineliai/">Žygimanto Augustino
      kūriniu</a> + 50 € vertės MO parduotuvės dovanų kuponas</div>),
    text: (
      <p>
        2 vietos nugalėtojui moksleiviui – išskirtiniai MO marškinėliai su vienu populiariausių parodos „Kodėl taip
        sunku mylėti?“ kūrinių.
        <br/>
        <br/>
        Tai – MO kolekcijos autoriaus, vieno žymiausių šiuolaikinių lietuvių tapytojų Žygimanto Augustino (ne, ne
        Žygimanto Augusto, o Augustino) tapybos darbas „Du Gracijai“ (2004–2005). Tokius apsivilkai ir visiems viskas
        aišku – marškinėliai patys yra tarsi meninė provokacija!
        <br/>
        <br/>
        Ką priderinti prie marškinėlių, atrasi MO parduotuvėje. Tačiau, į ką išmainyti 50 € MO parduotuvės dovanų kuponą
        tikrai nebus lengva apsispręsti! Užmesti akį į MO parduotuvę gali ir <Link
        to="https://www.facebook.com/momuziejus/shop">Facebook’e.</Link>
      </p>
    ),
    image: Place5
  },
  {
    place: "3 vieta",
    prize: (
      <div>Naktį šviečiantis krepšys su <a href="https://mo.lt/modiseja-2021-krepsys/">MO iliustracija</a> + 25 € vertės
        MO parduotuvės dovanų kuponas</div>),
    image: Place6,
    text: (
      <p>
        Nuotaikingų menininko Artiom Brančel iliustracijų MO muziejuje neįmanoma nepastebėti. Todėl vieną jų perkėlėme
        ant MO krepšio! Juo ir apdovanosime 3 vietos nugalėtoją moksleivį.
        <br/>
        <br/>
        Krepšį puošia smalsios, naktį šviečiančios akutės su knyga – tai MO skaityklos simbolis. Tačiau krepšys talpins
        ne tik knygas: čia galėsi susidėti naujus atradimus ir kitus džiaugsmus. Pavyzdžiui?
        <br/>
        <br/>
        Pavyzdžiui, tai, ką atrasi ir įsigysi MO parduotuvėje, panaudojęs 25 € vertės dovanų kuponą. O įdomių prekių bei
        išskirtinių suvenyrų čia – gausybė! Užmesti akį į MO parduotuvę gali ir <Link
        to="https://www.facebook.com/momuziejus/shop">Facebook’e.</Link>
      </p>
    )
  }
]

export default () => (
  <Layout>


    <div className="grid bg-blue">


      <div className="grid--cell w-67 index--logo mm-height">
        <div className="index--logo-info">
          <div>Kviečia dalyvauti</div>
          <div className="index--logo-info-logos">
            <a
              href="https://lrt.lt"
              target="_blank"
              rel="noopener noreferrer"
              title="LRT"
            >
              <img height="40px" src={LrtLogoDark} alt="LRT"/>
            </a>
            <a
              href="https://www.llri.lt/"
              target="_blank"
              rel="noopener noreferrer"
              title="LLRI"
            >
              <img height="40px" src={MmoLogoDark} alt="MMO"/>
            </a>
          </div>
        </div>


        <div className="img-d">
          <img src={TopIlliustration} alt="" style={{ maxWidth: "100%" }}/>
        </div>


      </div>

      <div className="grid--cell w-33 ">
        <div className="index--countdown">
          <div className="index--countdown-info">
            <div>Kviečia dalyvauti</div>
            <div className="index--countdown-info-logos">
              <a
                href="https://lrt.lt"
                target="_blank"
                rel="noopener noreferrer"
                title="LRT"
              >
                <img height="40px" src={LrtLogo} alt="LRT"/>
              </a>
              <a
                href="https://www.llri.lt/"
                target="_blank"
                rel="noopener noreferrer"
                title="LLRI"
              >
                <img height="40px" src={MmoLogo} alt="MMO"/>
              </a>
            </div>
          </div>


          <div className="index--countdown-completed">
            <h1 className="ended">
              PRIZAI
            </h1>
          </div>


        </div>
      </div>


    </div>


    <div className="prizes-page">

      <Box>
        <h1>Prizai</h1>

        <div className="text-container">


          <p>
            Projekto pradžioje, iki birželio 1d. skelbiame konkursą.
          </p>

          <p>
            Kiekvieną savaitę geriausiai ir greičiausiai viktoriną išsprendę dalyviai bus apdovanoti prizais:
            <br/>
            <ul>
              <li>Metinėmis žurnalo „IQ“ prenumeratomis!</li>
              <li>„LRT“ įsteigtais „Apple Watch“ išmaniaisiais laikrodžiais!</li>
              <li>„Telia“ įsteigtomis „JBL Flip 5 Eco“ kolonėlėmis!</li>
              <li>Mokykloms atiteks vadovėlių „Ekonomika per 31 valandą“ komplektai!</li>
              <li>Klasę, kurios moksleiviai ekonomikos viktoriną išspręs geriausiai, apdovanosime banko „Luminor“ įsteigtu prizu – „Digiklasė“ finansinio raštingumo kursu!</li>
              <li>“Biok” įsteigtais prizais!</li>
              <li>Geriausiai išsprendusį moksleivį apdovanosime 300 eurų piniginiu prizu!</li>
              <li>Geriausiai išsprendusį pilietį apdovanosime 300 eurų piniginiu prizu!</li>
              <li>Geriausiai išsprendusį studentą apdovanosime 300 eurų piniginiu prizu!</li>
              <li>Aktyviausiam mokytojui, kuris pritrauks daugiausiai moksleivių, atiteks 400 eurų piniginis prizas!*</li>


            </ul>
          </p>

          <p>
            * Mokytojas turi būti užsiregistravęs kaip viktorinos ambasadorius, tai padaryti galima parašius el.
            paštu {" "}
            <a href="mailto:ekonomikavisur@llri.lt" title="ekonomikavisur@llri.lt">
              ekonomikavisur@llri.lt
            </a>. Mokytojo klasėms registruojantis spręsti testą, pastaboje reikia nurodyti mokytojo vardą ir pavardę.
            Jei viktoriną mokytojas organizuoja skirtingose mokyklose, informuokite el. paštu – {" "}
            <a href="mailto:ekonomikavisur@llri.lt" title="ekonomikavisur@llri.lt">
              ekonomikavisur@llri.lt
            </a>
          </p>


        </div>
      </Box>


    </div>
  </Layout>
)
